import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FooterWaves from '../components/footer-waves';

function Impressum() {
  return (
    <Layout>
      <SEO title="Impressum" description="Wer wird sind und wie Sie und kontaktieren können." />

      <section className="bg-white py-8 mt-10 p-6">
        <div className="container max-w-5xl mx-auto m-8 text-gray-800">
          <h1 className="">Impressum</h1>

          <h2 className="">Verantwortlich für den Inhalt:</h2>
          <p>
            Novalab
            <br />
            Florian Moser
            <br />
            Oberfeld 21
            <br />
            3274 Bühl
          </p>
          <p>
            Telefon: 031 333 50 88
            <br />
            E-Mail: info@novalab.ch
          </p>

          <h2 className="text-3xl text-gray-800 font-bold leading-none mb-3 mt-8">
            Registereintrag:
          </h2>
          <p>Eintragung im Handelsregister.</p>
          <p>Registernummer: CHE-113.184.588</p>

          <h2 className="text-3xl text-gray-800 font-bold leading-none mb-3 mt-8">
            Haftung für Links
          </h2>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>

          <h2 className="text-3xl text-gray-800 font-bold leading-none mb-3 mt-8">
            Urheberrecht
          </h2>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem Urheberrecht. Die Vervielfältigung,
            Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
            sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>

          <p className="mt-16">
            Homepage Hero Photo by{' '}
            <a
              href="https://unsplash.com/@brookecagle?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
              className="text-blue-500 hover:underline"
            >
              Brooke Cagle
            </a>{' '}
            on{' '}
            <a
              href="/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
              className="text-blue-500 hover:underline"
            >
              Unsplash
            </a>
          </p>
        </div>
      </section>

      <FooterWaves />
    </Layout>
  );
}

export default Impressum;
